import * as Dialog from "@radix-ui/react-dialog";
import { PopupContent } from "~/components/popup/PopupContent";
import { PopupInput } from "app/components/component/input/PopupInput";
import { useEffect, useState } from "react";
import ToggleButton from "app/components/component/toggleButton/ToggleButton";
import { useSubmit } from "@remix-run/react";
import { fatchAddAdminApi } from "~/utils/admin/AddadminApi";
import { fatchGetUserIpApi } from "~/utils/ip/getUserIp";
import { Timer } from "~/utils/Timer";
import { MainPurpleButton } from "~/components/buttons/MainButtons";

export type OtpPopupProps = {
  children: any;
  open : boolean;
  setOpen : React.Dispatch<React.SetStateAction<boolean>>;
  submitButtonClick : (optMessage : string)=> void;
  reSendButtonClick : () => void;
  isErrorMessage? : string;
};

export const OtpPopup = ({
  children,
  open,
  setOpen,
  submitButtonClick,
  reSendButtonClick,
  isErrorMessage
}: OtpPopupProps) => {

  const [otp, setOtp] = useState("");

  const [reStart , setReStart] = useState(false);

  useEffect(()=>{
    if(open){
       setOtp("")
    }
    setReStart(false);
  },[open , reStart])

  const handleInputEnterKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
  
    if (e.key === "Enter") {
        if(otp.length > 1)submitButtonClick(otp)
    }
  };

  const [isClickDisabled, setIsClickDisabled] = useState(false);

  const handleClick = () => {
    if (!isClickDisabled) {
      setIsClickDisabled(true);

      setTimeout(() => {
        setIsClickDisabled(false);
      }, 10000); // 10초 동안 클릭 중단

      // 10초 제한이 걸리지 않았을경우 실행되게되는 함수
      setReStart(true);
      reSendButtonClick();
    }
  };

  return (
    <Dialog.Root open={open!== undefined ? open : true} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 bg-[#11111F] opacity-60" />
        <PopupContent
          title="OTP 인증"
          buttonLabel={"확인"}
          key={"adminAddButton"}
          buttonBackground={otp.length < 2 ? "rgba(130, 38, 175, 0.2)" : undefined}
          onSubmit={()=>{
            if(otp.length > 1) {
                submitButtonClick(otp)
            }
          }}
        >
            <div className="flex flex-col items-center gap-2 bg-ffq_navy_02 pb-[19px] pt-[23px] w-[320px]">
            <div className="flex items-center justify-end">
              <PopupInput
                inputHight="40px"
                inputTitle="OTP"
                onInputChange={(inputValue: any) => {
                   setOtp(inputValue);
                }}
                keyDownFunction={(e) => {handleInputEnterKeyPress(e)}}
                placeholder="OTP 입력"
              />
                <div style={{marginLeft : 10}}> <MainPurpleButton backGround={isClickDisabled ? "rgba(130, 38, 175, 0.2)" : undefined} onClick={handleClick} disabled={isClickDisabled} label={"재전송"} name="popupSubmit" /></div>
            </div>
           
            <div style={{color : "red" , display : isErrorMessage && isErrorMessage.length > 0 ? "flex" : "none"}} 
            className="flex w-[190px] items-center whitespace-pre-line text-[12px] pb-[19px] pt-[18px]">{isErrorMessage ? isErrorMessage : ""}</div>
            <div style={{marginTop : 2}}><Timer reStart={reStart}/></div>
          </div>
        </PopupContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
