import { ActionFunction, json, LoaderFunction } from "@remix-run/node";
import { Form, useActionData, useLoaderData } from "@remix-run/react";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import React, { useEffect } from "react";
import { createUserSession } from "~/session.server";
import { validateUser } from "~/utils/auth/ldap";
import {
  addFailedLoginAttempt,
  deleteFailedLoginAttempt,
  getFailedLoginCount,
  isLoginBlocked,
} from "~/utils/auth/loginLimit";
import { decodeRSA } from "~/utils/auth/rsaDecrypt";
import { adminExistsByCID } from "~/models/admin";
import {
  checkOtpAuthenticationWithInitialize,
  sendOtp,
  validateOtp,
} from "~/utils/auth/otp";
import { OtpPopup } from "~/components/popup/logInOtp/OtpPopup";
import logger from "~/log/logger";

export const handle = { invisibleSideBar: true };

type userUpdateData = {
  email: string | null;
  password: string | null;
};

export const loader: LoaderFunction = async ({ request }) => {
  return json({ ENV: { rsa_public_key: process.env.RSA_PUBLIC_KEY } });
};

export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData();

  const _action = formData.get("_action");

  console.log(_action);

  let email = formData.get("email") as string;
  let password = formData.get("password") as string;

  let inputOtpNumber = formData.get("inputOtpNumber") as string;

  if (email === null || email === "") {
    return json({
      message: "이메일이나 비밀번호가 누락되었습니다. 다시한번 확인해주세요.",
    });
  }

  try {
    email = decodeRSA(email);
    password = decodeRSA(password);
  } catch (error) {
    logger.error("[Decode Error]", error);
    return json({
      message: "사번, 비밀번호 암호화 에러",
    });
  }

  console.log(`Login Failed ${email}: ${await getFailedLoginCount(email)}`);
  console.log(`Login Blocked ${email}: ${await isLoginBlocked(email)}`);

  if (!(await adminExistsByCID(email))) {
    return json({
      message: "Error: 인증에 실패했습니다.",
    });
  }

  if (await isLoginBlocked(email)) {
    return json({
      message:
        "로그인 시도 5회 실패. 계정이 임시 잠금되었으므로 10분 후 시도해주세요.",
    });
  }

  let response;
  let user_name = "";
  let user_email = "";
  let user_department = "";
  let user_phoneNumber = "";

  try {
    if  (process.env.NODE_ENV === "development") {
         return createUserSession({
                    request: request,
                    userId: email,
                    remember: true,
                    redirectTo: "/admin/generationStatus",
                    username: user_name,
                    userEmail: user_email,
                    userDepartment: user_department,
                  });
       }
    response = await validateUser(email, password);

    user_name = response?.name ? response?.name : user_name;
    user_email = response?.email ? response?.email : user_email;
    user_department = response?.department
      ? response?.department
      : user_department;
    user_phoneNumber = response?.phoneNumber
      ? response?.phoneNumber
      : user_phoneNumber;

    await deleteFailedLoginAttempt(email);

    if (_action === "login" || _action === "reSend") {
      const sendOtpResponse = await sendOtp(email, user_name, user_phoneNumber);

      return json({ otpResult: sendOtpResponse, otpPopUpOpen: true });
    } else {
      const validateOtpResponse = await validateOtp(email, inputOtpNumber);

      if (validateOtpResponse) {
        const checkUserSussece = await checkOtpAuthenticationWithInitialize(
          email,
          inputOtpNumber
        );

        if (checkUserSussece) {
          return createUserSession({
            request: request,
            userId: email,
            remember: true,
            redirectTo: "/admin/generationStatus",
            username: user_name,
            userEmail: user_email,
            userDepartment: user_department,
          });
        } else {
          return json({
            otpMessage: String(
              "정상적인 방법으로 OTP 절차가 실행되지 않았습니다. 다시 한번 더 시도해주세요."
            ),
          });
        }
      } else {
        return json({
          otpMessage: String(
            "OTP가 일치하지 않습니다. 다시 한번 더 시도해주세요."
          ),
        });
      }
    }
  } catch (error) {
    addFailedLoginAttempt(email);
    return json({
      message: String(error),
    });
  }
};

export default function LoginPage() {
  const getActionData = useActionData<typeof action>();

  const [showPassword, setShowPassword] = React.useState(false);

  const [otpPopUpOpen, setOptPopupOpen] = React.useState(false);

  const [userSubmitOtp, setUserSubmitOtp] = React.useState("");

  const [userDataTrigger, setUSerTrigger] = React.useState<userUpdateData>({
    email: null,
    password: null,
  });

  const [userData, setUserData] = React.useState({
    email: "",
    password: "",
  });
  const handleInputEnterKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === "Enter") {
      window.document.getElementById("userEncryButton")!!.click();
    }
  };

  const actionData = useActionData<typeof action>();
  const loaderData = useLoaderData<typeof loader>();

  useEffect(() => {
    if (getActionData) {
      if(getActionData && !getActionData.message){
        setOptPopupOpen(getActionData.otpPopUpOpen);
      }
    }
  }, [getActionData]);

  useEffect(() => {
    if (userSubmitOtp)
      window.document.getElementById("userSubmitOtpButton")!!.click();
  }, [userSubmitOtp]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  // const labelCoustom = () => {
  //   return (
  //     <p style={{ fontSize: "12px", color: "#6D7081" }}>로그인 상태 유지</p>
  //   );
  // };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(() => {
    if (userDataTrigger.email !== null && userDataTrigger.password !== null) {
      window.document.getElementById("submitForm")!!.click();
    }
  }, [userDataTrigger]);

  const onValueChange = (
    value: string | boolean | number | object,
    key: string
  ) => {
    setUserData((prev) => ({ ...prev, [key]: value }));
  };

  const encryData = async () => {
    const JSEncrypt = (await import("jsencrypt")).default;
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(loaderData.ENV.rsa_public_key);

    let { email, password } = userData;
    email = encrypt.encrypt(email) as string;
    password = encrypt.encrypt(password) as string;

    return { email: email, password: password };
  };
  return (
    <Form className="flex h-full w-full" method="post">
      <div className="flex h-full w-3/5 items-center justify-center bg-[#826AFF]">
        <p style={{ fontSize: 60, color: "white" }}>
          {" "}
          KT AI Human Studio <br /> Admin
        </p>
      </div>

      <input
        id="email"
        name="email"
        readOnly={true}
        value={userDataTrigger.email ? userDataTrigger.email : ""}
        style={{ display: "none" }}
      ></input>

      <input
        id="password"
        name="password"
        readOnly={true}
        value={userDataTrigger.password ? userDataTrigger.password : ""}
        style={{ display: "none" }}
      ></input>

      <input
        id="inputOtpNumber"
        name="inputOtpNumber"
        readOnly={true}
        value={userSubmitOtp}
        style={{ display: "none" }}
      ></input>

      <OtpPopup
        open={otpPopUpOpen}
        setOpen={setOptPopupOpen}
        isErrorMessage={
          getActionData
            ? getActionData.otpMessage
              ? String(getActionData.otpMessage)
              : ""
            : ""
        }
        reSendButtonClick={() => {
          window.document.getElementById("reSendButton")!!.click();
        }}
        submitButtonClick={(otpMessage) => {
          setUserSubmitOtp(otpMessage);
        }}
        children={undefined}
      ></OtpPopup>

      <div className="flex w-2/5 items-center justify-center bg-white">
        <div className="flex flex-col justify-center">
          <span className="tems-center mb-8 flex h-[32px] w-[320px] items-center justify-center rounded-[4px] bg-[#E5E8F980]">
            <span className="text-[14px] text-[#6F77A3]">KT 관리자 페이지</span>
          </span>
          <span className="mb-6 block text-3xl">관리자 로그인</span>

          <p className="text-[12px] text-[#6D7081]">사원번호</p>

          <FormControl
            size="small"
            sx={{ width: "25ch" }}
            variant="outlined"
            style={{ marginBottom: 8, marginTop: 8, width: 320 }}
          >
            <OutlinedInput
              onKeyDown={handleInputEnterKeyPress}
              onChange={(value) => {
                onValueChange(value.target.value, "email");
              }}
              autoComplete="off"
              sx={{
                "&.Mui-focused > .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid",
                  borderColor: "#826AFF",
                },
                "&:hover > .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid",
                  borderColor: "#826AFF",
                },
              }}
              id="outlined-adornment-email"
              type="text"
              endAdornment={<InputAdornment position="end"></InputAdornment>}
            />
          </FormControl>

          <FormGroup className="mb-[5px]">
            {actionData?.email ? (
              <p style={{ color: "red", fontSize: "12px" }}>
                {actionData?.email ? actionData?.email : ""}
              </p>
            ) : (
              <p></p>
            )}
          </FormGroup>

          <p className="text-[12px] text-[#6D7081]">비밀번호</p>
          <FormControl
            size="small"
            sx={{ width: "25ch" }}
            variant="outlined"
            style={{ marginBottom: 8, marginTop: 8, width: 320 }}
          >
            <OutlinedInput
              onChange={(value) => {
                onValueChange(value.target.value, "password");
              }}
              onKeyDown={handleInputEnterKeyPress}
              onPaste={(value) => {
                onValueChange(value.clipboardData.getData("text"), "password");
              }}
              id="outlined-adornment-password"
              sx={{
                "&.Mui-focused > .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid",
                  borderColor: "#826AFF",
                },
                "&:hover > .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid",
                  borderColor: "#826AFF",
                },
              }}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword()}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormGroup className="mb-[5px]">
            {actionData?.message ? (
              <p style={{ color: "red", fontSize: "12px" }}>
                {actionData.message ? actionData.message : ""}
              </p>
            ) : (
              <p></p>
            )}
          </FormGroup>

          <button
            id="userSubmitOtpButton"
            className="userSubmitOtpButton"
            type="submit"
            name="_action"
            value="submitOtp"
            style={{ flex: "none" }}
          ></button>

          <button
            id="reSendButton"
            className="reSendButton"
            type="submit"
            name="_action"
            value="reSend"
            style={{ flex: "none" }}
          ></button>

          <button
            id="submitForm"
            className="submitForm"
            type="submit"
            name="_action"
            value="login"
            style={{ flex: "none" }}
          ></button>

          <button
            className="mt-[20px] flex h-11 w-80 cursor-pointer items-center justify-center rounded-[4px] bg-[#826AFF] text-[16px] text-[#FFFFFF]"
            type="button"
            id="userEncryButton"
            onClick={() => {
              if (userData.email !== null && userData.password !== null) {
                encryData().then((data) => {
                  setUSerTrigger({
                    email: data ? data.email : "setEmail",
                    password: data ? data.password : "setPassword",
                  });
                });
              }
            }}
          >
            로그인
          </button>
        </div>
      </div>
    </Form>
  );
}
