import React, { useState, useEffect, memo } from 'react';

export const Timer = memo(({ reStart }: { reStart: boolean }) => {
  const MINUTES_IN_MS = 5 * 60 * 1000;
  const INTERVAL = 1000;
  const [timeLeft, setTimeLeft] = useState<number>(MINUTES_IN_MS);

  const minutes = String(Math.floor((timeLeft / (1000 * 60)) % 60)).padStart(2, '0');
  const second = String(Math.floor((timeLeft / 1000) % 60)).padStart(2, '0');

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - INTERVAL);
    }, INTERVAL);

    if (timeLeft <= 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft]);

  // reStart prop이 true일 때 타이머를 재시작
  useEffect(() => {
    if (reStart) {
      setTimeLeft(MINUTES_IN_MS);
    }
  }, [reStart]);

  return (
    <div>
      {
        minutes === "00" && second === "00" ? (<div style={{fontSize : "12px"}}>OTP 가 만료되었습니다. 재전송을 눌러주세요.</div>) : (<div>{minutes} : {second}</div>)
      }
    </div>
  );
});
